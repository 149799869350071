
import {map} from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AssociationController } from 'src/features/common/association/association.controller';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AssociationApi } from 'src/features/common/association/association-api';

@Injectable()
export class AssociateBusinessAssociateContactInternalApi implements AssociationApi {
  public endpoint: string = 'associate/business-associate/';
  public suffix: string = '/default-roles';

  static createAssociationApi(
    apiHelper: ApiHelper,
    associationController: AssociationController,
    injector: Injector,
  ): AssociationApi {
    return new AssociateBusinessAssociateContactInternalApi(apiHelper);
  }

  constructor(
    private apiHelper: ApiHelper,
  ) {}

  public findAll = (baId: string | number): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baId + this.suffix + '/internal').pipe(
      map((items) => {
        return this.appendRollType(items);
      }));
  }

  public filter = (baId: string | number, assetList: any): Observable<any> => {
    return this.apiHelper.request(this.endpoint + baId + this.suffix + '/internal', { body: assetList }).pipe(
      map((items) => {
        return this.appendRollType(items);
      }));
  }

  public save = (dataList: any[]): Observable<any> => {
    return this.apiHelper.request(this.endpoint + dataList[0].baId + this.suffix, { body: dataList });
  }

  // Helpers
  private appendRollType = (items: any[]): any[] => {
    return _.each(items, (item) => {
      item.roleType = 'internal';
    });
  }
}
