export const menu: any = [
  {
    label: 'Nominations',
    state: 'nominations',
    auth: 'CustomerActivities.menu.nominations',
    baseMenu: true,
    children: [
      {
        label: 'Nomination',
        state: 'nomination',
        auth: 'CustomerActivities.menu.nominations.nomination',
        children: [
          {
            label: 'Nomination Matrix',
            state: 'nominations/nomination/nomination-matrix',
            auth: 'CustomerActivities.menu.nominations.nomination.nomination'
          },
          {
            label: 'Future Nomination Matrix',
            state: 'nominations/nomination/future-nomination-matrix',
            auth: 'CustomerActivities.menu.nominations.nomination.futureNomination'
          },
          {
            label: 'In-Field Storage Transfer',
            state: 'nominations/nomination/in-field-storage-transfer',
            auth: 'CustomerActivities.menu.nominations.nomination.inFieldXfer'
          },
          {
            label: 'Nomination History',
            state: 'nominations/nomination/nomination-history',
            auth: 'CustomerActivities.menu.nominations.nomination.nomHistory'
          },
          {
            label: 'Nomination Reconciliation',
            state: 'nominations/nomination/nomination-reconciliation',
            auth: 'CustomerActivities.menu.nominations.nomination.nomReconciliation'
          },
          {
            label: 'Ship Voyage Details',
            state: 'nominations/nomination/ship-voyage-details',
            auth: 'CustomerActivities.menu.nominations.nomination.shipVoyageDetails'
          },
          {
            label: 'Ship Voyage Details Form',
            state: 'nominations/nomination/ship-voyage-details-form',
            auth: 'CustomerActivities.menu.nominations.nomination.shipVoyageDetailsForm'
          },
          {
            label: 'Receipt Nomination',
            state: 'nominations/nomination/receipt-nomination',
            auth: 'CustomerActivities.menu.nominations.nomination.lngReceiptNom'
          },
          {
            label: 'Receipt Summary',
            state: 'nominations/nomination/receipt-summary',
            auth: 'CustomerActivities.menu.nominations.nomination.lngReceiptSummary'
          },
          {
            label: 'Ship Information',
            state: 'nominations/nomination/ship-information',
            auth: 'CustomerActivities.menu.nominations.nomination.shipInfo'
          }
        ]
      },
      {
        label: 'Confirmations',
        state: 'nominations/confirmations',
        auth: 'CustomerActivities.menu.nominations.confirmations',
        children: [
          {
            label: 'Confirmation',
            state: 'nominations/confirmations/confirmation',
            auth: 'CustomerActivities.menu.nominations.confirmations.confirmation'
          },
          {
            label: 'Confirmation Maintenance',
            state: 'nominations/confirmations/confirmation-maintenance',
            auth: 'CustomerActivities.menu.nominations.confirmations.confirmationMaintenance'
          },
          {
            label: 'Confirmation Summary',
            state: 'nominations/confirmations/confirmation-summary',
            auth: 'CustomerActivities.menu.nominations.confirmations.confirmationSummary'
          },
          {
            label: 'Confirmation vs Scheduled Quantity',
            state: 'nominations/confirmations/confirmation-vs-scheduledquantity',
            auth: 'CustomerActivities.menu.nominations.confirmations.confirmationScheduledQuantity'
          },
          {
            label: 'Confirmation Files',
            state: 'nominations/confirmations/confirmation-files',
            auth: 'CustomerActivities.menu.nominations.confirmations.confirmationFiles'
          }
        ]
      },
      {
        label: 'EDI Confirmation',
        state: 'nominations/edi-confirmation',
        auth: 'CustomerActivities.menu.nominations.ediConfirmation'
      },
      {
        label: 'EDI Nomination',
        state: 'nominations/edi-nomination',
        auth: 'CustomerActivities.menu.nominations.ediNomination'
      },
      {
        label: 'Off System Nomination',
        state: 'nominations/off-system-nomination',
        auth: 'CustomerActivities.menu.nominations.offSystemNomination'
      },
      {
        label: 'Scheduled Quantity',
        state: 'nominations/scheduled-quantity',
        auth: 'CustomerActivities.menu.nominations.scheduledQuantity',
        children: [
          {
            label: 'Scheduled Quantity Matrix',
            state: 'nominations/scheduled-quantity/matrix',
            auth: 'CustomerActivities.menu.nominations.scheduledQuantity.scheduledQuantityMatrix'
          },
          {
            label: 'Scheduled Quantity For Operator',
            state: 'nominations/scheduled-quantity/for-operator',
            auth: 'CustomerActivities.menu.nominations.scheduledQuantity.schQtyForOperator'
          }
        ]
      },
      {
        label: 'Scheduling Quantities Summary',
        state: 'nominations/scheduling-quantities-summary',
        auth: 'CustomerActivities.menu.nominations.scheduledQuantitySummary',
        children: [
          {
            label: 'Quantities by Contract',
            state: 'nominations/scheduling-quantities-summary/by-contract',
            auth: 'CustomerActivities.menu.nominations.scheduledQuantitySummary'
          },
          {
            label: 'Quantities by Location',
            state: 'nominations/scheduling-quantities-summary/by-location',
            auth: 'CustomerActivities.menu.nominations.scheduledQuantitySummary'
          }
        ]
      },
      {
        label: 'SubNom Configuration',
        state: 'nominations/sub-nominations',
        auth: 'CustomerActivities.menu.nominations.subNomConfig'
      },
      {
        label: 'Cycle Process Status',
        state: 'nominations/cycle-process-status',
        auth: 'CustomerActivities.menu.flowManagement.scheduling.cycleProcessStatus'
      }
    ]
  },
  {
    label: 'Flowing Gas',
    state: 'flowing-gas',
    auth: 'CustomerActivities.menu.flowManagement',
    baseMenu: true,
    children: [
      {
        label: 'Pre-determined Allocation',
        state: 'flowing-gas/pre-determined-allocation',
        auth: 'CustomerActivities.menu.flowManagement.pdaAllocation'
      },
      {
        label: 'Maintain Allocation',
        state: 'flowing-gas/maintain-allocation',
        auth: 'CustomerActivities.menu.flowManagement.maintainAllocation',
        children: [
          {
            label: 'Manual Allocation',
            state: 'flowing-gas/maintain-allocation/manual-allocation',
            auth: 'CustomerActivities.menu.flowManagement.maintainAllocation.manualAllocation'
          },
          {
            label: 'Manual PDA',
            state: 'flowing-gas/maintain-allocation/manual-pda',
            auth: 'CustomerActivities.menu.flowManagement.maintainAllocation.manualPda'
          },
          {
            label: 'Option PDA',
            state: 'flowing-gas/maintain-allocation/option-pda',
            auth: 'CustomerActivities.menu.flowManagement.maintainAllocation.optionPda'
          },
          {
            label: 'NNS Election',
            state: 'flowing-gas/maintain-allocation/nns-election',
            auth: 'CustomerActivities.menu.flowManagement.maintainAllocation.nnsElection'
          },
          {
            label: 'System Protection Warning',
            state: 'flowing-gas/maintain-allocation/system-protection-warning',
            auth: 'CustomerActivities.menu.flowManagement.maintainAllocation.spw'
          }
        ]
      },
      {
        label: 'Allocation',
        state: 'flowing-gas/allocation',
        auth: 'CustomerActivities.menu.flowManagement.allocation'
      },
      {
        label: 'Imbalance',
        state: 'flowing-gas/imbalance',
        auth: 'CustomerActivities.menu.flowManagement.imbalance',
        children: [ {
          label: 'Shipper Imbalance',
          state: 'flowing-gas/imbalance/shipper',
          auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance',
          children: [ {
            label: 'View Transport/OBA Contract Imbalance History',
            state: 'flowing-gas/imbalance/shipper/oba-imbal-history',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.viewTransOBAContractImbalance'
          },{
            label: 'Shipper Imbalance',
            state: 'flowing-gas/imbalance/shipper/ship-imbal',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.ptpAllocation'
          }, {
            label: 'View Svc Req K/Location Daily Quantities',
            state: 'flowing-gas/imbalance/shipper/contract-location-quantities',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.viewKLocImbalance'
          }, {
            label: 'View Daily Contract Balance',
            state: 'flowing-gas/imbalance/shipper/daily-k-bal',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.viewContractImbalance'
          }, {
            label: 'Parking Service Imbalance',
            state: 'flowing-gas/imbalance/shipper/parking-service',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.parkingServiceImbalance'
          }, {
            label: 'Shipper Imbalance Summary',
            state: 'flowing-gas/imbalance/shipper/shipper-imbalance-summary',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.shipperImbalance.shipperImbalanceSummary'
          } ]
        },
          {
            label: 'Operator/Producer Imbalance',
            state: 'flowing-gas/imbalance/operProducer',
            auth: 'CustomerActivities.menu.flowManagement.imbalance.operatorImbalance',
            children: [ {
              label: 'View Svc Req K/Loc Daily Qty',
              state: 'flowing-gas/imbalance/operProducer/contract-location-quantities',
              auth: 'CustomerActivities.menu.flowManagement.imbalance.operatorImbalance.viewSvcKLocDailyImbalance'
            }, {
              label: 'View Location Imbalances',
              state: 'flowing-gas/imbalance/operProducer/location-imbalances',
              auth: 'CustomerActivities.menu.flowManagement.imbalance.operatorImbalance.viewLocationImbalance'
            } ]
          } ]
      },
      {
        label: 'View Imbalance',
        state: 'flowing-gas/view-imbalance',
        auth: 'CustomerActivities.menu.flowManagement.viewImbalance'
      },
      {
        label: 'Measurement',
        state: 'flowing-gas/measurement',
        auth: 'CustomerActivities.menu.flowManagement.measurement',
        children: [ {
          label: 'Location Vol/Qty',
          state: 'flowing-gas/measurement/location-qty',
          auth: 'CustomerActivities.menu.flowManagement.measurement.locationVolume'
        }, {
          label: 'Meter Vol/Qty',
          state: 'flowing-gas/measurement/meter-qty',
          auth: 'CustomerActivities.menu.flowManagement.measurement.meterVolume'
        }, {
          label: 'Measured Volume Exception',
          state: 'flowing-gas/measurement/measure-vol-exception',
          auth: 'CustomerActivities.menu.flowManagement.measurement.measuredVolumeException'
        } ]
      },
      {
        label: 'Contract To Contract Transfer',
        state: 'flowing-gas/contract-to-contract-transfer',
        auth: 'CustomerActivities.menu.flowManagement.contToContXfer'
      },
      {
        label: 'Contract To Contract Transfer Rules',
        state: 'flowing-gas/c2c-transfer-rules',
        auth: 'CustomerActivities.menu.flowManagement.contractToContractXferRule'
      },
      {
        label: 'Point To Point Transaction Type Matrix',
        state: 'flowing-gas/point-to-point',
        auth: 'CustomerActivities.menu.flowManagement.ptpMatrixTranType'
      },
      {
        label: 'Scheduling Charge',
        state: 'flowing-gas/scheduling-charge',
        auth: 'CustomerActivities.menu.flowManagement.schedulingCharge',
        children: [ {
          label: 'Location View',
          state: 'flowing-gas/scheduling-charge/location-view',
          auth: 'CustomerActivities.menu.flowManagement.schedulingCharge.schedulingChargeLocation'
        }, {
          label: 'Contract View',
          state: 'flowing-gas/scheduling-charge/contract-view',
          auth: 'CustomerActivities.menu.flowManagement.schedulingCharge.schedulingChargeContract'
        }, {
          label: 'Scheduling Charge Waiver',
          state: 'flowing-gas/scheduling-charge/scheduling-charge-waiver',
          auth: 'CustomerActivities.menu.flowManagement.schedulingCharge.schedulingChargeWaiver'
        } ]
      },
      {
        label: 'Cashout',
        state: 'flowing-gas/cashout',
        auth: 'CustomerActivities.menu.flowManagement.cashout',
        children: [ {
          label: 'Cashout Adjustment',
          state: 'flowing-gas/cashout/cashout-adjustment',
          auth: 'CustomerActivities.menu.flowManagement.cashout.cashoutAdjustment'
        }, {
          label: 'Cashout Details',
          state: 'flowing-gas/cashout/cashout-details',
          auth: 'CustomerActivities.menu.flowManagement.cashout.cashoutDetails'
        } ]
      },
      {
        label: 'Alert Day Maintenance',
        state: 'flowing-gas/alert-day-maintenance',
        auth: 'CustomerActivities.menu.flowManagement.alertDayMaintenance'
      },
      {
        label: 'Null Point Maintenance',
        state: 'flowing-gas/null-point-maintenance',
        auth: 'CustomerActivities.menu.flowManagement.nullPointMaintenance'
      },
      {
        label: 'GDS Storage Overrun Adjustment',
        state: 'flowing-gas/storage-overrun-adjustment',
        auth: 'CustomerActivities.menu.flowManagement.gdsStorageOverrunAdjustment'
      },
      {
        label: 'Imbalance Adjustment',
        state: 'flowing-gas/imbalance-adjustment',
        auth: 'CustomerActivities.menu.flowManagement.imbalanceAdjustment'
      },
      {
        label: 'View Posted Imbalances',
        state: 'flowing-gas/view-posted-imbalances',
        auth: 'CustomerActivities.menu.flowManagement.postedImbalances'
      },
      {
        label: 'Authorization to Post Imbalances',
        state: 'flowing-gas/auth-imbalances',
        auth: 'CustomerActivities.menu.flowManagement.authToPostImbalances'
      },
      {
        label: 'Initiate and Confirm Imbalance Trades',
        state: 'flowing-gas/trade-imbalances',
        auth: 'CustomerActivities.menu.flowManagement.imbalanceTrading'
      },
      {
        label: 'Update Balance Date',
        state: 'flowing-gas/update-balance-date',
        auth: 'CustomerActivities.menu.flowManagement.updateBalanceDate'
      },
      {
        label: 'OBA Monitoring',
        state: 'flowing-gas/oba-monitoring',
        auth: 'CustomerActivities.menu.flowManagement.obaMonitoring'
      },
      {
        label: 'Scheduling',
        state: 'flowing-gas/scheduling',
        auth: 'CustomerActivities.menu.flowManagement.scheduling',
        children: [ {
          label: 'GDS Imbalance Storage Scheme',
          state: 'flowing-gas/scheduling/gds-imbalance-storage-scheme',
          auth: 'CustomerActivities.menu.flowManagement.scheduling.gdsStorageScheme'
        },
          {
            label: 'Pool Zone Activity',
            state: 'flowing-gas/scheduling/pool-zone-activity',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.poolZoneActivity'
          },
          {
            label: 'Power Plant Balancing Configuration',
            state: 'flowing-gas/scheduling/power-plant-balancing-configuration',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.powerPlantBalancingConfiguration'
          },
          {
            label: 'GDS Imbalance',
            state: 'flowing-gas/scheduling/gds-imbalance',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.gdsImbalance'
          }, {
            label: 'Intraday Approval',
            state: 'flowing-gas/scheduling/intraday-approval',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.intradayApproval'
          },
          {
            label: 'Manually Scheduled Paths',
            state: 'flowing-gas/scheduling/scheduled-paths',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.scheduledPaths'
          },
          {
            label: 'Intraday Nomination List',
            state: 'flowing-gas/scheduling/intraday-nomination-list',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.intradayNomination'
          },
          {
            label: 'Confirmation Approval List',
            state: 'flowing-gas/scheduling/confirmation-approval-list',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.confirmationApprovalList'
          },
          {
            label: 'Pool Balancing Menu',
            state: 'flowing-gas/scheduling/pool-balancing-menu',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.poolBalancingMenu',
            children: [ {
              label: 'View Pool Balance Info',
              state: 'flowing-gas/scheduling/pool-balancing-menu/view-pool-balance-info',
              auth: 'CustomerActivities.menu.flowManagement.scheduling.poolBalancingMenu.poolBalancing'
            },
              {
                label: 'Maintain Parking/Unparking Limits',
                state: 'flowing-gas/scheduling/pool-balancing-menu/maintain-parking-limits',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.poolBalancingMenu.parkCondition'
              }
            ]
          }, {
            label: 'Auto Scheduling',
            state: 'flowing-gas/scheduling/autoscheduling',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.autoSchedulingMenu',
            children: [ {
              label: 'Automatic Scheduling',
              state: 'flowing-gas/scheduling/autoscheduling/automatic-scheduling',
              auth: 'CustomerActivities.menu.flowManagement.scheduling.autoSchedulingMenu.autoScheduling'
            },
              {
                label: 'Automatic Scheduling Params',
                state: 'flowing-gas/scheduling/autoscheduling/automatic-scheduling-params',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.autoSchedulingMenu.autoSchedulingParams'
              }
            ]
          },
          {
            label: 'Capacity Allocation Wizard',
            state: 'flowing-gas/scheduling/scheduling-capacity-allocation',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.capacityAllocation'
          },
          {
            label: 'Capacity Allocation Cycle View',
            state: 'flowing-gas/scheduling/scheduling-cycle-details',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.schedulingcycle'
          },
          {
            label: 'Nomination/Confirmation Approval',
            state: 'flowing-gas/scheduling/nomination-confirmation-approval',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.nomApproval'
          },
          {
            label: 'Morning Report',
            state: 'flowing-gas/scheduling/morning-report',
            auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu',
            children: [
              {
                label: 'Point',
                state: 'flowing-gas/scheduling/morning-report/point',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu.morningReportPoint'
              },
              {
                label: 'Segment',
                state: 'flowing-gas/scheduling/morning-report/segment-morningreport',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu.morningReportSegment'
              },
              {
                label: 'Storage',
                state: 'flowing-gas/scheduling/morning-report/storage',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu.morningReportStorage'
              },
              {
                label: 'Outage',
                state: 'flowing-gas/scheduling/morning-report/outage',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu.morningReportOutage'
              },
              {
                label: 'Ship Schedule',
                state: 'flowing-gas/scheduling/morning-report/ship-schedule',
                auth: 'CustomerActivities.menu.flowManagement.scheduling.morningReportMenu.morningReportShipSchedule'
              }
            ]
          }
        ]
      },
      {
        label: 'Auto Pool Balancing',
        state: 'flowing-gas/auto-pool-balancing',
        auth: 'CustomerActivities.menu.flowManagement.autoPoolBalancing',
        children: [ {
          label: 'AutoPark Service Election',
          state: 'flowing-gas/auto-pool-balancing/auto-park-service',
          auth: 'CustomerActivities.menu.flowManagement.autoPoolBalancing.parkCondition'
        } ]
      },
      {
        label: 'View Allocation Exceptions',
        state: 'flowing-gas/allocation-exceptions',
        auth: 'CustomerActivities.menu.flowManagement.allocationException'
      },
      {
        label: 'View Unauthorized Gas',
        state: 'flowing-gas/view-unauthorized-gas',
        auth: 'CustomerActivities.menu.flowManagement.unAuthGas'
      },
      {
        label: 'Operation Lock',
        state: 'flowing-gas/operation-lock',
        auth: 'CustomerActivities.menu.flowManagement.operationLock'
      },
      {
        label: 'Storage Monitoring',
        state: 'flowing-gas/storage-monitoring',
        auth: 'CustomerActivities.menu.flowManagement.storageMonitoring'
      },
      {
        label: 'Storage Balances',
        state: 'flowing-gas/storage-balances',
        auth: 'CustomerActivities.menu.flowManagement.viewStorageContractImbalance'
      },
      {
        label: 'Parking Balances',
        state: 'flowing-gas/parking-balances',
        auth: 'CustomerActivities.menu.flowManagement.parkingBalances',
        pipelineGroups : [ 'PE', 'EN' ],
        children: [
          {
            label: 'By Area',
            state: 'flowing-gas/parking-balances/by-area',
            auth: 'CustomerActivities.menu.flowManagement.parkingBalances.byArea'
          },
          {
            label: 'By Contract',
            state: 'flowing-gas/parking-balances/by-contract',
            auth: 'CustomerActivities.menu.flowManagement.parkingBalances.byContract'
          }
        ]
      },
      {
        label: 'Allocate Daily',
        state: 'flowing-gas/allocate-daily',
        auth: 'CustomerActivities.menu.flowManagement.allocateDaily'
      },
      {
        label: 'Upload PTR Data',
        state: 'flowing-gas/upload-ptr-data',
        auth: 'CustomerActivities.menu.flowManagement.uploadPtrData'
      },
      {
        label: 'Upload Resv Chg Credit Qty',
        state: 'flowing-gas/upload-resv-chg-credit-qty',
        auth: 'CustomerActivities.menu.flowManagement.uploadResvChgCreditQty'
      }
    ]
  },
  {
    label: 'Invoicing',
    state: 'invoicing',
    auth: 'CustomerActivities.menu.invoicing',
    baseMenu: true,
    children: [
      {
        label: 'View Valuations',
        state: 'invoicing/view-valuations',
        auth: 'CustomerActivities.menu.invoicing.flowViewValuatedData'
      },
      {
        label: 'Invoice Messages',
        state: 'invoicing/invoice-messages',
        auth: 'CustomerActivities.menu.invoicing.invoiceMessage'
      },
      {
        label: 'Adjustment',
        state: 'invoicing/adjustment',
        auth: 'CustomerActivities.menu.invoicing.adjustment'
      },
      {
        label: 'Invoice Dates',
        state: 'invoicing/invoice-dates',
        auth: 'CustomerActivities.menu.invoicing.invoiceDates'
      },
      {
        label: 'View Invoices',
        state: 'invoicing/view-invoices',
        auth: 'CustomerActivities.menu.invoicing.viewInvoice'
      },
      {
        label: 'Index Prices',
        state: 'invoicing/index-prices',
        auth: 'CustomerActivities.menu.invoicing.indexPrices'
      },
      {
        label: 'Penalty Credit',
        state: 'invoicing/penalty-credit',
        auth: 'CustomerActivities.menu.invoicing.penaltyCollections',
        baseMenu: true,
        children: [
          {
            label: 'Penalty Collection',
            state: 'invoicing/penalty-credit/penalty-collection',
            auth: 'CustomerActivities.menu.invoicing.penaltyCollections'
          },
          {
            label: 'Penalty Credit Revenue Collection',
            state: 'invoicing/penalty-credit/penalty-credit-revenue-collection',
            auth: 'CustomerActivities.menu.invoicing.penaltyCollections'
          }
        ]
      },
      {
        label: 'View Archived Billing Determinant Reports',
        state: 'invoicing/archived-billing-determinant-reports',
        auth: 'CustomerActivities.menu.invoicing.viewArchivedBillingDeterminants',
        pipelineGroups : [ 'PE', 'ET', 'EN' ]
      },
      {
        label: 'View Archived Cashin-Cashout Reports',
        state: 'invoicing/archived-cashin-cashout-reports',
        auth: 'CustomerActivities.menu.invoicing.viewArchivedCashinCashoutReports',
        pipelineGroups : [ 'FG' ]
      },
      {
        label: 'View Discounts Tariff PPA',
        state: 'invoicing/discounts-tariff-ppa',
        auth: 'CustomerActivities.menu.invoicing.valuationTypePPA'
      },
      {
        label: 'View Accounting Period Param',
        state: 'invoicing/accounting-period-param',
        auth: 'CustomerActivities.menu.invoicing.acctPeriodParam'
      },
      {
        label: 'External Point Xref',
        state: 'invoicing/external-point-xref',
        auth: 'CustomerActivities.menu.invoicing.externalPointXref'
      },
      {
        label: 'Upload Invoice Data',
        state: 'invoicing/upload-invoice-data',
        auth: 'CustomerActivities.menu.invoicing.uploadInvoiceData'
      },
      {
        label: 'Aggregation Default Rate',
        state: 'invoicing/aggregation-default-rate',
        auth: 'CustomerActivities.menu.invoicing.aggregationDefaultRate'
      },
      {
        label: 'Pt to Pt Rate Detail',
        state: 'invoicing/pt-to-pt-rate-detail',
        auth: 'CustomerActivities.menu.invoicing.pointToPoint'
      },
      {
        label: 'GL Accounts',
        state: 'invoicing/gl-accounts',
        auth: 'CustomerActivities.menu.invoicing.glAccounts'
      },
      {
        label: 'View GL/AR Upload Data',
        state: 'invoicing/view-gl-ar-upload-data',
        auth: 'CustomerActivities.menu.invoicing.viewGlArUploadData'
      },
      {
        label: 'Contract Allocation',
        state: 'invoicing/contract-allocation',
        auth: 'CustomerActivities.menu.invoicing.contractAllocation'
      }

    ]
  },
  {
    label: 'Capacity Release',
    state: 'capacity-release',
    auth: 'CustomerActivities.menu.capacityRelease',
    baseMenu: true,
    children: [
      {
        label: 'Offers / Bids / Awards',
        state: 'capacity-release/offers',
        auth: 'CustomerActivities.menu.capacityRelease.capRelAuction'
      },
      {
        label: 'Recalls / Reputs',
        state: '',
        auth: 'CustomerActivities.menu.capacityRelease',
        pipelineGroups : [ 'FG', 'ET', 'EN' ],
        children: [
          {
            label: 'Recalls',
            state: 'capacity-release/recalls',
            auth: 'CustomerActivities.menu.capacityRelease.capRelAuctionRecall'
          },
          {
            label: 'Reputs',
            state: 'capacity-release/reputs',
            auth: 'CustomerActivities.menu.capacityRelease.capRelAuctionReput'
          },
          {
            label: 'Recall / Reput Status',
            state: 'capacity-release/recall-reput-status',
            auth: 'CustomerActivities.menu.capacityRelease.capRelRecallReputStatus'
          }
        ]
      },
      {
        label: 'Recalls',
        state: 'capacity-release/recalls',
        auth: 'CustomerActivities.menu.capacityRelease.capRelAuctionRecall',
        pipelineGroups : [ 'PE' ]
      },
      {
        label: 'Storage Relationship',
        state: 'capacity-release/storage-relationship',
        auth: 'CustomerActivities.menu.viewCapacityRelease.storageRelationship',
        pipelineGroups : [ 'PE', 'ET' ]
      }
    ]
  },
  {
    label: 'Contracts',
    state: 'contracts',
    auth: 'CustomerActivities.menu.contracts',
    baseMenu: true,
    children: [
      {
        label: 'Contract',
        state: 'contracts/contract',
        auth: 'CustomerActivities.menu.contracts.contract'
      },
      {
        label: 'Amendment',
        state: 'contracts/amendment',
        auth: 'CustomerActivities.menu.contracts.amendment'
      },
      {
        label: 'Contract/Amendment Summary',
        state: 'contracts/contract-amendment-summary',
        auth: 'CustomerActivities.menu.contracts.contractSummary'
      },
      {
        label: 'Lock Contract',
        state: 'contracts/contract-lock',
        auth: 'CustomerActivities.menu.contracts.lockContract'
      },
      {
        label: 'Capacity Review',
        state: 'contracts/capacity-review',
        auth: 'CustomerActivities.menu.contracts.capacityReview',
        pipelineGroups : [ 'FG' ]
      },
      {
        label: 'LNG Supply Measurement Contract',
        state: 'contracts/lng-supply-measurement-contract',
        auth: 'CustomerActivities.menu.contracts.lngSupplyContract'
      },
      {
        label: 'Transactions',
        state: 'contracts/transactions',
        auth: 'CustomerActivities.menu.contracts.transactions',
        children: [
          {
            label: 'Transaction Details',
            state: 'contracts/transactions/transaction-details',
            auth: 'CustomerActivities.menu.contracts.transactions.createTransaction'
          },
          {
            label: 'Associate To Nominations',
            state: 'contracts/transactions/associate-to-nominations',
            auth: 'CustomerActivities.menu.contracts.transactions.nominationTransaction'
          }
        ]
      }
    ]
  },
  {
    label: 'Data Administration',
    state: 'data-administration',
    auth: 'CustomerActivities.menu.dataAdministration',
    baseMenu: true,
    children: [
      {
        label: 'Business Associate',
        state: 'data-administration/business-associate',
        auth: 'CustomerActivities.menu.dataAdministration.businessAssociate'
      },
      {
        label: 'Contact',
        state: 'data-administration/contact',
        auth: 'CustomerActivities.menu.dataAdministration.contact'
      },
      {
        label: 'Address',
        state: 'data-administration/address',
        auth: 'CustomerActivities.menu.dataAdministration.address'
      },
      {
        label: 'Global Contact Update',
        state: 'data-administration/global-contact-update',
        auth: 'CustomerActivities.menu.dataAdministration.contactCombine'
      },
      {
        label: 'BA Combine',
        state: 'data-administration/ba-combine',
        auth: 'CustomerActivities.menu.dataAdministration.bACombine'
      },
      {
        label: 'Future Changes',
        state: 'data-administration/future-changes',
        auth: 'CustomerActivities.menu.dataAdministration.dataAdminChange'
      }
    ]
  },
  {
    label: 'Structured Products',
    state: 'structured-products',
    auth: 'CustomerActivities.menu.structuredProducts',
    baseMenu: true,
    children: [
      {
        label: 'Define Parameters',
        state: 'structured-products/define-parameters',
        auth: 'CustomerActivities.menu.structuredProducts.defineParameters'
      },
      {
        label: 'Define Rights & Prices',
        state: 'structured-products/define-right-price',
        auth: 'CustomerActivities.menu.structuredProducts.defineRights'
      },
      {
        label: 'Define Service',
        state: 'structured-products/define-service',
        auth: 'CustomerActivities.menu.structuredProducts.defineService'
      },
      {
        label: 'Define Inputs',
        state: 'structured-products/define-inputs',
        auth: 'CustomerActivities.menu.structuredProducts.defineInputs'
      },
      {
        label: 'Define Component Matrix',
        state: 'structured-products/define-component-matrix',
        auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix',
        children: [
          {
            label: 'Base Service(Tariff)',
            state: 'structured-products/define-component-matrix/base-service',
            auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix.baseService'
          },
          {
            label: 'Child Service(Discount)',
            state: 'structured-products/define-component-matrix/child-service',
            auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix.childService'
          },
          {
            label: 'Upload Rates and Exceptions',
            state: 'structured-products/define-component-matrix/upload-rates-and-exceptions',
            auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix.uploadRates'
          },
          {
            label: 'Define Components',
            state: 'structured-products/define-component-matrix/define-components',
            auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix.defineComponents'
          },
          {
            label: 'Field Backhaul Fuel Pct',
            state: 'structured-products/define-component-matrix/field-backhaul-fuel-pct',
            auth: 'CustomerActivities.menu.structuredProducts.defineComponentMatrix.backhaulFuelPct'
          }
        ]
      }
    ]
  },
  {
    label: 'Physical',
    state: 'physical',
    auth: 'CustomerActivities.menu.physical',
    baseMenu: true,
    children: [
      {
        label: 'Location',
        state: 'physical/location',
        auth: 'CustomerActivities.menu.physical.location'
      },
      {
        label: 'Meter',
        state: 'physical/meter',
        auth: 'CustomerActivities.menu.physical.meter'
      },
      {
        label: 'Capacity',
        state: 'physical/capacity',
        auth: 'CustomerActivities.menu.physical.capacity'
      },
      {
        label: 'Location Group',
        state: 'physical/location-group',
        auth: 'CustomerActivities.menu.physical.locationGroup'
      },
      {
        label: 'Segment',
        state: 'physical/segment',
        auth: 'CustomerActivities.menu.physical.segment'
      },
      {
        label: 'Off System Point Groups',
        state: 'physical/off-system-point-groups',
        auth: 'CustomerActivities.menu.physical.offSystemPointGroup'
      }
    ]
  },
  {
    label: 'Portfolio Management',
    state: 'portfolio-management',
    auth: 'CustomerActivities.menu.portfolioManagement',
    baseMenu: true,
    children: [
      {
        label: 'Forecast',
        state: 'forecast',
        auth: 'CustomerActivities.menu.portfolioManagement.forecast',
        children: [
          {
            label: 'Forecast Contract',
            state: 'portfolio-management/forecast/forecast-contract',
            auth: 'CustomerActivities.menu.portfolioManagement.forecast.portfolioContract'
          },
          {
            label: 'Forecast Amendment',
            state: 'portfolio-management/forecast/forecast-amendment',
            auth: 'CustomerActivities.menu.portfolioManagement.forecast.portfolioAmendment'
          },
          {
            label: 'Forecast Contract/Amendment Summary',
            state: 'portfolio-management/forecast/forecast-contract-amendment-summary',
            auth: 'CustomerActivities.menu.portfolioManagement.forecast.portfolioContractSummary'
          }
        ]
      },
      {
        label: 'ForeCast Valuation and Settlement',
        state: 'forecast-valuation-settlement',
        auth: 'CustomerActivities.menu.portfolioManagement.foreCastValuationSettlement',
        children: [
          {
            label: 'ForeCast Valuation Version',
            state: 'portfolio-management/forecast-valuation-settlement/view-forecast-valuation-version',
            auth: 'CustomerActivities.menu.portfolioManagement.foreCastValuationSettlement.maintainForeCastValuationVersion'
          },
          {
            label: 'ForeCast Valuation Adjustment',
            state: 'portfolio-management/forecast-valuation-settlement/forecast-valuation-adjustment',
            auth: 'CustomerActivities.menu.portfolioManagement.foreCastValuationSettlement.foreCastValuationAdjustement'
          },
          {
            label: 'View ForeCast Valuation',
            state: 'portfolio-management/forecast-valuation-settlement/view-forecast-valuation',
            auth: 'CustomerActivities.menu.portfolioManagement.foreCastValuationSettlement.foreCastViewValuation'
          }
        ]
      },
      {
        label: 'Physical',
        state: 'physical',
        auth: 'CustomerActivities.menu.portfolioManagement.foreCastPhysical',
        children: [
          {
            label: 'Location',
            state: 'portfolio-management/physical/location',
            auth: 'CustomerActivities.menu.portfolioManagement.foreCastPhysical.foreCastLocation'
          },
          {
            label: 'Segment',
            state: 'portfolio-management/physical/segment',
            auth: 'CustomerActivities.menu.portfolioManagement.foreCastPhysical.foreCastSegment'
          }
        ]
      },
      {
        label: 'Define Component Matrix',
        state: 'Define-Component-Matrix',
        auth: 'CustomerActivities.menu.portfolioManagement.proposeddefineComponentMatrix',
        children: [
          {
            label: 'Base Service (Tariff)',
            state: 'portfolio-management/define-component-matrix/base-service-tariff',
            auth: 'CustomerActivities.menu.portfolioManagement.proposeddefineComponentMatrix.proposedbaseService'
          }
        ]
      }
    ]
  },
  {
    label: 'Reports',
    state: 'reports',
    auth: 'CustomerActivities.menu.reports',
    baseMenu: true,
    children: [
      {
        label: 'Schedule & View Reports',
        state: 'reports/schedule-and-view-reports',
        auth: 'CustomerActivities.menu.reports.reportingWorkflowRunner'
      },
      {
        label: 'Report Batch',
        state: 'reports/report-batch',
        auth: 'CustomerActivities.menu.reports.reportBatchBA'
      }
    ]
  },
  {
    label: 'Workflow',
    state: 'workflow',
    auth: 'CustomerActivities.menu.workflow',
    baseMenu: true,
    children: [
      {
        label: 'Workflow Runner',
        state: 'workflow/schedule-and-view-workflow',
        auth: 'CustomerActivities.menu.workflow.workflowRunner'
      }
    ]
  },
  {
    label: 'Infopost Maintenance',
    state: 'infopost-maintenance',
    auth: 'CustomerActivities.menu.infopost',
    baseMenu: true,
    children: [
      {
        label: 'Pipeline Capacity',
        state: 'infopost/pipeline-capacity',
        auth: 'CustomerActivities.menu.infopost.pipelineCapacity'
      },
      {
        label: 'Notices (Critical, Non-Critical & Outages)',
        state: 'infopost/notices',
        auth: 'CustomerActivities.menu.infopost.notices'
      },
      {
        label: 'Tariff Waiver',
        state: 'infopost/tariff-waiver',
        auth: 'CustomerActivities.menu.infopost.tariffWaiverDetail'
      },
      {
        label: 'Transactional Postings',
        state: 'infopost/transactional-postings',
        auth: 'CustomerActivities.menu.infopost.transactionalPosting'
      },
      {
        label: 'Group Email',
        state: 'infopost/group-email',
        auth: 'CustomerActivities.menu.infopost.groupEmail'
      }
    ]
  },
  {
    label: 'Administration',
    state: 'administration',
    auth: 'CustomerActivities.menu.administration',
    baseMenu: true,
    children: [
      {
        label: 'Admin Utilities',
        state: 'administration/admin-utilities',
        auth: 'CustomerActivities.menu.administration.utilities'
      },
      {
        label: 'Communication',
        state: 'administration/communication',
        auth: 'CustomerActivities.menu.administration.emailFaxLog'
      },
      {
        label: 'Deadline Extension',
        state: 'administration/deadline-extension',
        auth: 'CustomerActivities.menu.administration.deadlineExtension',
        featureFlags: [ 'asset-live' ]
      },
      {
        label: 'Messages',
        state: 'administration/messages',
        auth: 'CustomerActivities.menu.administration.messages'
      },
      {
        label: 'Call Log',
        state: 'administration/call-log',
        auth: 'CustomerActivities.menu.administration.callLog',
        children: [
          {
            label: 'Call Log Details',
            state: 'administration/call-log/call-log-details',
            auth: 'CustomerActivities.menu.administration.callLog'
          },
          {
            label: 'Customer Maintenance',
            state: 'administration/call-log/customer-maintenance',
            auth: 'CustomerActivities.menu.administration.callLog'
          },
          {
            label: 'Contact Maintenance',
            state: 'administration/call-log/contact-maintenance',
            auth: 'CustomerActivities.menu.administration.callLog'
          }
        ]
      },
      {
        label: 'Group Management',
        state: 'administration/group-management',
        auth: 'CustomerActivities.menu.administration.eventGroups'
      },
      {
        label: 'Table Maintenance',
        state: 'administration/table-maintenance',
        auth: 'CustomerActivities.menu.administration.tableMaintenance',
        children: [
          {
            label: 'Codes',
            state: 'administration/table-maintenance/codes',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.codes'
          },
          {
            label: 'Asset Configuration Param',
            state: 'administration/table-maintenance/asset-config-param',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.assetConfigParams'
          },
          {
            label: 'Archive Table Name',
            state: 'administration/table-maintenance/archive-table-name',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.archTableNames'
          },
          {
            label: 'Asset Param',
            state: 'administration/table-maintenance/asset-param',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.assetParams'
          },
          {
            label: 'CapacityRelease Index',
            state: 'administration/table-maintenance/cr-index',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.crIndex'
          },
          {
            label: 'Define Recurrence Patterns',
            state: 'administration/table-maintenance/define-recur-patterns',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.defineRecurPatterns'
          },
          {
            label: 'Email Templates',
            state: 'administration/table-maintenance/email-templates',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.emailTemplates'
          },
          {
            label: 'WorkFlow Associations',
            state: 'administration/table-maintenance/workflow-associations',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.workflowAssociations'
          },
          {
            label: 'EDI Process Schedule',
            state: 'administration/table-maintenance/edi-process-schedule',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.ediProcessSchedule'
          },
          {
            label: 'Confirmation Report Execution Schedule',
            state: 'administration/table-maintenance/confirmation-report-execution-schedule',
            auth: 'CustomerActivities.menu.administration.tableMaintenance.confirmationReportExecSchedule'
          }
        ]
      },
      {
        label: 'Security',
        state: 'administration/security',
        auth: 'CustomerActivities.menu.administration.security',
        children: [
          {
            label: 'EDI Security',
            state: 'administration/security/edi-security',
            auth: 'CustomerActivities.menu.administration.security.ediSecurityMatrix'
          },
          {
            label: 'Roles',
            state: 'administration/security/roles',
            auth: 'CustomerActivities.menu.administration.security.roles'
          }
        ]
      },
      {
        label: 'User Profile',
        state: 'administration/user-profile',
        auth: 'CustomerActivities.menu.administration.maintainProfile'
      }
    ]
  },
  {
    label: 'Informational Postings',
    state: 'information-posting',
    auth: 'CustomerActivities.menu.informationPosting',
    baseMenu: true,
    children: [
      {
        label: 'Website',
        state: 'infoPost',
        auth: 'CustomerActivities.menu.informationPosting'
      }
    ]
  },
  {
    label: 'Site Map',
    state: 'site-map',
    auth: 'CustomerActivities.menu.siteMap',
    baseMenu: true,
    children: [
      {
        label: 'View',
        state: 'site-map',
        auth: 'CustomerActivities.menu.siteMap'
      }
    ]
  }
];
