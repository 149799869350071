
import {merge as observableMerge, Subject, Observable,  fromEvent ,  of } from 'rxjs';

import {debounceTime, tap, auditTime,  concatMap, take, map } from 'rxjs/operators';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  Type,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {
  CellValueChangedEvent,
  ColDef, Column,
  ColumnController, ColumnResizedEvent, Constants,
  CsvExportParams,
  DragStartedEvent,
  GetContextMenuItems,
  GetContextMenuItemsParams,
  GridOptionsWrapper,
  GridReadyEvent,
  MenuItemDef,
  PinnedRowModel,
  ProcessCellForExportParams,
  RowNode,
  _ as Utils
} from '@ag-grid-community/core';
import {
  AgGridAngular,
  AngularFrameworkComponentWrapper,
  AngularFrameworkOverrides
} from '@ag-grid-community/angular';

import * as _ from 'lodash';
import {UiGridApi} from 'src/ag-grid-wrapper';
import {
  AutoUnsubscribables,
  AutoUnsubscriber
} from 'src/cad/shared/mixins/auto-unsubscriber.mixin';

import {DirtyStatus} from '../table.constants';
import {RowMessageIconConfig} from 'src/ag-grid-wrapper/row/message-icon/message-icon.component';
import {TableRowEditAddFactory} from '../ui-grid-api/row-edit-add.factory';
import {TableRowEditAutoSaveFactory} from '../ui-grid-api/row-edit-auto-save.factory';
import {TableRowEditDeleteFactory} from '../ui-grid-api/row-edit-delete.factory';
import {TableRowEditFactory} from '../ui-grid-api/row-edit.factory';
import {TableRowFooterAggregationFactory} from '../ui-grid-api/row-footer-aggregation.factory';
import {CadTableConfigurer} from './cad-table-configurer';
import {ColumnFilterFactory} from 'src/ag-grid-wrapper/ui-grid-api/column-filter.factory';
import {TableHeaderFilterRendererComponent} from 'src/ag-grid-wrapper/header/filter/filter-header.component';
import {UserPreferenceService} from 'cad/common/services/preferences/user-preferences';
import {UserCacheService} from 'cad/common/store/user/services/user-cache.service';
import {RouterService} from 'cad/core/services/router/router.service';
import {PrintScreenService} from 'src/common/services/print-screen/print-screen.service';
import {TableRegistryService} from '../services/table-registry.service';
import * as moment from 'moment-timezone';
import UserPreferences = cad.UserPreferences;

@Component({
  selector: 'ui-table',
  template: '',
  styleUrls: [
    './table.less'
  ],
  encapsulation: ViewEncapsulation.None,
  /**
   * The CadTableConfigurer is added as a provider here as opposed
   * to in the grid module because it depends on Renderer2, which is
   * only available in a service if the service is exclusive
   * to a component
   */
  providers: [ CadTableConfigurer, AngularFrameworkComponentWrapper, AngularFrameworkOverrides ],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent extends AgGridAngular implements OnChanges, OnDestroy {

  @AutoUnsubscriber() subs: AutoUnsubscribables;

  // must be used in conjunction with rowDetailComponent
  @Input() public onGridReady : any = this.onAgGridReady.bind(this);
  @Input() public onGridDestroyed: () => void;
  @Input() public defaultColDef: ColDef = {
    headerComponentFramework: TableHeaderFilterRendererComponent,
    suppressNavigable: false,
    suppressMenu: true,
    sortable: true,
    resizable: true,
    filter: false,
    menuTabs: []
  };
  @Input() public defaultExportParams: CsvExportParams = {
    processCellCallback: (params: ProcessCellForExportParams): string => {
      if (params
        && params.column
        && params.column.getColDef()) {
        if (_.includes([ 'date', 'dateTime' ], params.column.getColDef().type)) {
          return this.getFormattedDateForExcel(this.processCellCallbackFn(params));
        } else if (_.includes([ 'number' ], params.column.getColDef().type)) {
          return params.value;
        }
      }
      return this.processCellCallbackFn(params);
    }
  };
  @Input() public excelStyles: any[] = [
    {
      id: 'dateType',
      dataType: 'dateTime',
      numberFormat: { format: 'mm/dd/yyyy;@' }
    },
    {
      id: 'dateTimeType',
      dataType: 'dateTime',
      numberFormat: { format: 'mm/dd/yyyy hh:mm:ss AM/PM;@' }
    },
    {
      id: 'ag-numeric-cell',
      dataType: 'number'
    },
    {
      id: 'footer-header-cell',
      dataType: 'string'
    }
  ];
  @Input() public id : string;
  @Input() public hideRowExpand: boolean = false;
  @Input() public rowDetailComponent: Type<any>;
  @Input() public onSaveRow: Promise<any>;
  @Input() public enableRowAutoSave: boolean = true;
  @Input() public enableSorting: boolean = true;
  @Input() public enableFilter: boolean = false;
  @Input() public showFooter: boolean;
  @Input() public enableRowModifiedFields: boolean = true;
  @Input() public navigationMenu: any;
  @Input() public validationVisual: boolean = false;
  @Input() public showSingleSelect: boolean;
  @Input() public enableNavigationHighlights: boolean;
  @Input() public showMultiSelect: boolean;
  @Input() public showSortOrder: boolean;
  @Input() public actionMenu: any;  // Should be ActionMenu type?
  @Input() public showDelete: any;
  @Input() public rowMessage: RowMessageIconConfig;
  @Input() public disableEdit: boolean = false;
  @Input() public minHeight: number;
  @Input() public shrinkToDataHeight: boolean = true;
  @Input() public renderFullHeight: boolean = false;
  @Input() public dynamicColumns: boolean = true;
  @Input() public stopEditingOnScroll: boolean = false;
  @Input() public enableRangeSelection: boolean = true;
  @Input() public rowSelection: string = 'single';
  @Input() public popupParent: any = document.querySelector('body');
  @Input() public undoRedoCellEditing: boolean = true;
  @Input() public undoRedoCellEditingLimit: number = 20;

  @Output() public dataChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public dataChangeRaw: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() public rowRemoved: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rowAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() public itemsRemoved: EventEmitter<any> = new EventEmitter();

  /**
   * Override
   */
  @Input('data') public rowData: any[] = [];
  @Input() public clipboardDeliminator: string;
  @Input() public getContextMenuItems: GetContextMenuItems = this.getContextMenuItemsFn.bind(this);
  @Input() public processCellForClipboard: (params: ProcessCellForExportParams) => any = this.processCellCallbackFn.bind(this);
  @Input() public suppressHorizontalScroll: boolean = true;
  @Input() public suppressPropertyNamesCheck: boolean = true;
  @Input() public headerHeight: number = 46;
  @Input() public rowHeight: number = 30;
  @Input() public suppressRowHoverClass: boolean = true;
  @Input() public layoutInterval: number = 500;
  @Input() public suppressRowClickSelection: boolean = false;
  @Input() public headerCheckboxSelection : boolean = false;
  @Input() public headerCheckboxSelectionFilteredOnly : boolean = false;
  @Input() public showExpand: boolean = true;
  @Input() public expandIcon: string = 'expand_more';
  @Input() public collapseIcon: string = 'expand_less';
  @Input() public expandTooltip: string = null;
  @Input() public collapseTooltip: string = null;
  @Input() public confirmDelete: boolean = false;
  @Input() public customContextMenuItems: (string | MenuItemDef)[] = [];
  @Input()
  public set suppressMenuFilterPanel(value: boolean) {
    if (value) {
      this.suppressMenuItem('filterMenuTab');
    }
  }
  @Input()
  public set suppressMenuMainPanel(value: boolean) {
    if (value) {
      this.suppressMenuItem('generalMenuTab');
    }
  }
  @Input()
  public set suppressMenuColumnPanel(value: boolean) {
    if (value) {
      this.suppressMenuItem('columnsMenuTab');
    }
  }
  @Input() public noPrint: boolean;
  @Output() public rowUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() public rowCellUpdated: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cellStatusChanged: EventEmitter<any> = new EventEmitter<any>();

  public resizeListener: Function;
  public initialized: boolean = false;
  public api: UiGridApi;
  @Input() public isRowMaster: Function = (node: any) => !!this.rowDetailComponent;
  public readonly ensureDomOrder: boolean = true;
  public readonly suppressColumnVirtualisation: boolean = true;
  public showFooterRegistered: boolean = false;
  public routeUrl:string = '';

  /**
   * custom column types.  This keeps ag-grid from
   * complaining that these column types are not defined
   */
  public columnTypes: any = {
    custom: {},
    currency: {},
    date: {},
    number: {},
    lookup: {},
    text: {},
    checkbox: {},
    email: {},
    labelbox: {},
    'action-menu': {},
    select: {},
    numberStr: {},
  };
  public tableRegistryKey: string;
  private _throttledSetDynamicTableHeightFn: Function & _.Cancelable;
  private _throttledExecSizeColumnsToFitFn: Function & _.Cancelable;
  private _throttledRefreshColDefs: Function & _.Cancelable;
  private _selectOnInit: Function;
  private _dataChangeEvent: Subject<string> = new Subject<string>();
  private userPrefsData: cad.UserPreferences;
  public columnState: any = null;
  public sortModel: { colId: string, sort: string; }[];

  constructor(public renderer: Renderer2,
              /**
               * required for ag-grid angular wrapper
               */
              public element: ElementRef,
              public viewContainer: ViewContainerRef,
              angularFrameworkOverrides: AngularFrameworkOverrides,
              frameworkComponentWrapper: AngularFrameworkComponentWrapper,
              public resolver: ComponentFactoryResolver,
              public configurer: CadTableConfigurer,
              public zone: NgZone,

              /**
               * UiGridApi factories
               */
              public columnFilterFactory: ColumnFilterFactory,
              public rowAddFactory: TableRowEditAddFactory,
              public rowAutoSaveFactory: TableRowEditAutoSaveFactory,
              public rowDeleteFactory: TableRowEditDeleteFactory,
              public rowEditFactory: TableRowEditFactory,
              public rowFooterAggregationFactory: TableRowFooterAggregationFactory,
              private userPrefs: UserPreferenceService,
              private userCacheService: UserCacheService,
              private router: RouterService,
              private printScreenService: PrintScreenService,
              private tableRegistryService: TableRegistryService) {
    super(element, viewContainer, angularFrameworkOverrides, frameworkComponentWrapper, resolver);
    this.configurer.setGridTheme(this, element.nativeElement);
    this._throttledSetDynamicTableHeightFn = _.throttle(this.calculateAndSetDynamicTableHeight, 500, { leading: false, trailing: true });
    this._throttledExecSizeColumnsToFitFn = _.throttle(this.execSizeColumnsToFit, 500, { leading: false, trailing: true });
    this._throttledRefreshColDefs = _.throttle(this.refreshColDefs, 500, { leading: false, trailing: true });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.subs.newSub = this.rowDataChanged.subscribe((): void => {
      if (this.shrinkToDataHeight && !this.renderFullHeight) {
        this._throttledSetDynamicTableHeightFn();
      }
    });
    this.subs.newSub = this.firstDataRendered
    .pipe(concatMap(() => {
      if (!this.isSuppressHorizontalScroll()) {
        this.columnApi.autoSizeAllColumns();
        return this.columnResized.asObservable();
      }
      return of(null);
    }))
    .subscribe((event: ColumnResizedEvent): void => {
      if (event && event.finished && event.source === 'autosizeColumns') {
        let gridPanel = _.get(this.api, 'gridPanel');
        if (!(gridPanel && gridPanel.isHorizontalScrollShowing())) {
          this.api.sizeColumnsToFit();
        }
        if (this.columnApi && this.columnState) {
          this.columnApi.setColumnState(this.columnState);
        }
        if (this.api && this.sortModel) {
          this.api.setSortModel(this.sortModel);
        }
      }
    });
    this.subs.newSub = this.modelUpdated.subscribe(() => {
      if (this.shrinkToDataHeight && !this.renderFullHeight) {
        this._throttledSetDynamicTableHeightFn();
      }
    });
    this.subs.newSub = this.scrollVisibilityChanged.subscribe((data: any) => {
      if (this.shrinkToDataHeight && !this.renderFullHeight) {
        this._throttledSetDynamicTableHeightFn();
      }
      let gridPanel = _.get(this.api, 'gridPanel');
      const el = this.element.nativeElement.querySelector('.ag-body-horizontal-scroll-viewport');
      if (!(gridPanel && gridPanel.isHorizontalScrollShowing())) {
        this.renderer.setStyle(el, 'overflow-x', 'hidden');
      } else {
        this.renderer.setStyle(el, 'overflow-x', 'scroll');
      }
    });
    this.subs.newSub = observableMerge(
      this.cellEditingStarted.asObservable(),
      this.cellEditingStopped.asObservable(),
      this.rowUpdated.asObservable(),
      this.modelUpdated.asObservable(),
      this.displayedColumnsChanged.asObservable()
    ).pipe(
    auditTime(500)).subscribe((emittedValue: any) => {
      this.cellStatusChanged.emit(emittedValue);
    });
    if (this.renderFullHeight) {
      this.calculateAndSetTableHeight();
    }
    /*    User preference for the table, are loaded here if already exits.
    * And to find if table has preferences we need it tableId,
    * current Route, and it's parent component*/
    this.subs.newSub = this.userPrefs.getPreferences()
    .pipe(
      concatMap((userPreferences: UserPreferences) => this.zone.onStable.asObservable().pipe(map(() => userPreferences), take(1)))
    )
    .subscribe((data: UserPreferences) => {
      if (this.userCacheService.getUserState()
        && this.userCacheService.getUserState().user
        && !_.isNil(this.userCacheService.getUserState().user.currentContext)
        && !_.isNil(data)) {
        let assetGroupCd = this.userCacheService.getUserState().user.currentContext.assetGroupCd;
        let assetNbr: number = this.userCacheService.getUserState().user.currentContext.assetNbr;
        this.userPrefsData = data;
        let _root = '_root';
        this.routeUrl = '';
        let currentRoute: string = this.getCurrentRouteUrl(this.router.routerState.snapshot[_root].children[0]);
        let tableId = UserPreferenceService.generateTableId(this);
        this.extracted(tableId, data, assetGroupCd, assetNbr, currentRoute);
      }
    });
  }

  private extracted(tableId: string, data: cad.UserPreferences, assetGroupCd: string, assetNbr: number, currentRoute: string): void {
    if (!_.isNil(tableId && data.tablePreferences)) {
      let gridUserPreferences: any = UserPreferenceService.findTargetTablePref(tableId, data.tablePreferences, assetGroupCd, assetNbr, currentRoute);
      if (gridUserPreferences && gridUserPreferences[ 0 ]) {
        if (gridUserPreferences[ 0 ].preference[ 0 ]) {
          this.columnState = gridUserPreferences[ 0 ].preference[ 0 ];
          this.columnApi.setColumnState(gridUserPreferences[ 0 ].preference[ 0 ]);
        }
        if (gridUserPreferences[ 0 ].preference[ 1 ]) {
          this.sortModel = gridUserPreferences[ 0 ].preference[ 1 ];
          this.api.setSortModel(gridUserPreferences[ 0 ].preference[ 1 ]);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.renderFullHeight) {
      if (changes.renderFullHeight.currentValue) {
        // Cancel the pending dynamic height calculation, if any
        this._throttledSetDynamicTableHeightFn.cancel();
        this.calculateAndSetTableHeight();
      } else {
        if (this.shrinkToDataHeight) {
          this._throttledSetDynamicTableHeightFn();
        }
      }
    }
    if (changes) {
      _.forEach(changes, (change: SimpleChange, key: string) => {
        if (!change.isFirstChange()) {
          this.onFeatureChanged(key, change.currentValue);
        }
      });
    }
    if (this.showFooter && !this.showFooterRegistered) {
      this.showFooterRegistered = true;
      this.rowFooterAggregationFactory.onGridApiRegistered(this);
    }
  }

  public registerCadApis(): void {
    this.columnFilterFactory.onGridApiRegistered(this);
    this.rowEditFactory.onGridApiRegistered(this);
    this.rowAddFactory.onGridApiRegistered(this);
    this.rowDeleteFactory.onGridApiRegistered(this);
    if (this.enableRowAutoSave) {
      this.rowAutoSaveFactory.onGridApiRegistered(this);
      this.api.rowEdit.savePromise = this.onSaveRow;
    }
    if (this.showFooter) {
      this.showFooterRegistered = true;
      this.rowFooterAggregationFactory.onGridApiRegistered(this);
    }
  }

  public unregisterCadApis(): void {
    this.columnFilterFactory.unRegisterGridListener(this);
    this.rowEditFactory.unRegisterGridListener(this);
    this.rowAddFactory.unRegisterGridListener(this);
    this.rowAutoSaveFactory.unRegisterGridListener(this);
    this.rowDeleteFactory.unRegisterGridListener(this);
    this.rowFooterAggregationFactory.unRegisterGridListener(this);
    delete this.api.rowEdit;
    this.columnDefs = null;
    this.gridOptions = null;
  }

  ngOnDestroy(): void {
    if (this.onGridDestroyed) {
      this.onGridDestroyed();
    }
    this._throttledSetDynamicTableHeightFn.cancel();
    this._throttledExecSizeColumnsToFitFn.cancel();
    this._throttledRefreshColDefs.cancel();
    this.unregisterCadApis();
    this.tableRegistryService.removeTable(this.tableRegistryKey);
    super.ngOnDestroy(); // mandatory
  }

  public onAgGridReady(event: GridReadyEvent): void {
    let gridPanel = (event && event.api) ? (event.api as any).gridPanel : null;

    // TODO: Remove the below if statement once ag-grid gets upgaded to version 23.0.0
    /* if (event && event.api && (event.api as any).clipboardService) {
      (event.api as any).clipboardService.updateCellValue = (
        rowNode: RowNode,
        column: Column,
        value: any,
        currentRow: RowNode,
        cellsToFlash: any,
        type: any,
        changedPath: any): void => {
        if (!rowNode ||
          !currentRow ||
          !column ||
          !column.isCellEditable(rowNode) ||
          column.isSuppressPaste(rowNode)) {
          return;
        }
        let processedValue = (event.api as any).clipboardService.userProcessCell(
          rowNode,
          column,
          value,
          (event.api as any).clipboardService.gridOptionsWrapper.getProcessCellFromClipboardFunc(),
          type
        );
        (event.api as any).clipboardService.valueService.setValue(rowNode, column, processedValue, Constants.SOURCE_PASTE);
        let cellId: string = (event.api as any).clipboardService.cellPositionUtils.createIdFromValues(currentRow.rowIndex, column, currentRow.rowPinned);
        cellsToFlash[ cellId ] = true;
        if (changedPath) {
          changedPath.addParentNode(rowNode.parent, [ column ]);
        }
      };
    } */
    this.registerCadApis();
    this.configurer.configure(this);
    this.setupColumnSizeSubscriptions();
    if (gridPanel && (gridPanel as any).eAllCellContainers) {
      (gridPanel as any).eAllCellContainers.forEach((container: any) =>
        container.addEventListener('contextmenu', (evt: MouseEvent) => {
          if (evt && !evt.defaultPrevented) {
            evt.preventDefault();
          }
        })
      );
    }
    this.fixIESelectionBug();
    if (this._selectOnInit) {
      this._selectOnInit();
      this._selectOnInit = undefined;
    }
    if (event
      && event.api
      && (event.api as UiGridApi).rowEdit
      && this.defaultExportParams) {
      _.assign(this.defaultExportParams, {
        columnKeys: (event.api as UiGridApi).rowEdit.getUserColumns().map((column: Column) => column.getColId())
      });
    }
    this.tableRegistryKey = this.tableRegistryService.addTable(this.id, this);
  }

  public refreshColDefs(): void {
    if (this.api) {
      this.api.setColumnDefs(this.columnDefs);
      if (this.columnApi && this.columnState) {
        this.columnApi.setColumnState(this.columnState);
      }
      if (this.sortModel) {
        this.api.setSortModel(this.sortModel);
      }
      this.execSizeColumnsToFit();
    }
  }

  public prependColumn(colDef: any): void {
    if (!this.columnDefs) {
      this.columnDefs = [];
    }
    this.columnDefs.unshift(colDef);

  }

  public appendColumn(colDef: any): void {
    if (!this.columnDefs) {
      this.columnDefs = [];
    }
    this.columnDefs.push(colDef);

  }

  public removeColumn(colDef: any): void {
    if (colDef && _.isArray(this.columnDefs)) {
      let colDefIndex: number = _.findIndex(this.columnDefs, { field: colDef.field});

      if (colDefIndex > -1) {
        this.columnDefs.splice(colDefIndex, 1);
        this._throttledRefreshColDefs();
      }
    }
  }

  public setSelection(key: any, value: any, supressEvents: any = true): void {
    if (this.api) {
      let selected = false;
      this.api.forEachNode((rowNode: RowNode) => {
        if (!selected && !_.isNil(rowNode.data) && rowNode.data[key] === value) {
          this.api.selectNode(rowNode, false, supressEvents);
          selected = true;
        }
      });
      if (!selected) {
        console.warn('No row to select with values', { key , value });
      }
    } else {
      this._selectOnInit = () => { this.setSelection(key, value, supressEvents); };
    }
  }
  public getRequiredColumnNames(): string[] {
    let reqColNames: string[] = [];
    const reqCols = this.getRequiredColumns();
    for (let i = 0; i < reqCols.length; i++) {
      reqColNames.push(reqCols[i].field);
    }
    return reqCols;
  }
  public getRequiredColumns(): any {
    return _.filter(this.columnDefs, {required: true});
  }
  public getRequiredColumnErrors(rowData: any): any {
    let reqCols = this.getRequiredColumns();
    let reqColErrors = [];

    for (let i = 0; i < reqCols.length; i++) {
      let fieldName = reqCols[i].field;
      let splitIndex = fieldName.indexOf('.');
      if(splitIndex>0) {
        fieldName = fieldName.slice(0,splitIndex);
      }
      let cellValue = rowData[fieldName];
      // let gridCol = this.getcol(reqCols[ i ].name);
      // let cellValue = grid.getCellDisplayValue(gridRow, gridCol) || grid.getCellValue(gridRow, gridCol);
      if (_.isUndefined(cellValue) || (!_.isUndefined(cellValue) && cellValue === '' )) {
        reqColErrors.push(reqCols[i].headerName);
      } else if (_.isObject(cellValue)) {
        if(this.checkProperties(cellValue)) {
          reqColErrors.push(reqCols[i].headerName);
        }
      }
    }
    return reqColErrors;
  }
  public checkProperties(obj: any): boolean {
    for (let key in obj) {
      if (obj[ key ] !== null && obj[ key ] !== '') {
        return false;
      }
    }
    return true;
  }

  public isSuppressHorizontalScroll(): boolean {
    return (this.gridOptions
    && (this.gridOptions.suppressHorizontalScroll === true || `${this.gridOptions.suppressHorizontalScroll}` === 'true'));
  }

  public calculateAndSetTableHeight(): void {
    if (!this.shrinkToDataHeight && !this.renderFullHeight) {
      this.setTableHeights('250px', '250px', '250px');
    }
    if (this.renderFullHeight) {
      this.setTableHeights('250px', '100%', '100vh');
    }
  }

  public calculateAndSetDynamicTableHeight(): void {
    if (this.api && this.api.getRenderedNodes().length > 0) {
      this.setTableHeights('0', `${this.getFullHeight()}px`, '250px');
    } else {
      this.setTableHeights('90px', '90px', '90px');
    }
  }

  public setTableHeights(min: string, main: string, max: string): void {
    if (this.element) {
      const el = this.element.nativeElement;
      if(this.minHeight) {
        this.renderer.setStyle(el, 'min-height', `${this.minHeight}px`);
      } else {
        this.renderer.setStyle(el, 'min-height', min);
      }
      this.renderer.setStyle(el, 'height', main);
      this.renderer.setStyle(el, 'max-height', max);
    }
  }

  public getFullHeight(): number {
    if (!this.api) {
      return 0;
    }
    let gridOptionsWrapper: GridOptionsWrapper = (this.api as any).gridOptionsWrapper;
    let gridPanel = (this.api as any).gridPanel;
    let pinnedRowModel: PinnedRowModel = (this.api as any).pinnedRowModel;
    let fullHeight: number;
    let floatingTopHeight: number;
    let floatingBottomHeight: number;

    // instantiate the full height from full page height
    fullHeight = this.api.getModel().getCurrentPageHeight();
    // add the total header height
    fullHeight += this.getTotalHeaderHeight();
    if (pinnedRowModel) {
      // padding top covers the header and the pinned rows on top
      floatingTopHeight = pinnedRowModel.getPinnedTopTotalHeight();
      if (floatingTopHeight) {
        // adding 1px for cell bottom border
        floatingTopHeight += 1;
      }
      fullHeight += floatingTopHeight;
      // bottom is just the bottom pinned rows
      floatingBottomHeight = pinnedRowModel.getPinnedBottomTotalHeight();
      if (floatingBottomHeight) {
        // adding 1px for cell bottom border
        floatingBottomHeight += 1;
      }
      fullHeight += floatingBottomHeight;
    }
    if (gridPanel) {
      const bodyContainerEl: any = gridPanel.getCenterContainer();
      if (bodyContainerEl) {
        this.renderer.setStyle(bodyContainerEl, 'display', 'block');
      }
      if (gridPanel.isHorizontalScrollShowing()) {
        const scrollbarWidth = gridOptionsWrapper.getScrollbarWidth();
        fullHeight += Math.max(0, _.isNumber(scrollbarWidth) ? scrollbarWidth : 0);
      }
    }
    if (this.element && this.element.nativeElement) {
      const statusBarEl: any = this.element.nativeElement.querySelector('.ag-status-bar');
      const pagingPanelEl: any = this.element.nativeElement.querySelector('.ag-paging-panel');

      if (statusBarEl) {
        fullHeight += statusBarEl.getBoundingClientRect().height;
      }
      if (pagingPanelEl) {
        fullHeight += pagingPanelEl.getBoundingClientRect().height;
      }
    }
    return fullHeight;
  }

  public getTotalHeaderHeight(): number {
    if (!this.api) {
      return this.headerHeight;
    }
    let columnCtrl: ColumnController = (this.api as any).columnController;
    let gridOptionsWrapper: GridOptionsWrapper = (this.api as any).gridOptionsWrapper;
    let headerRowCount: number = columnCtrl.getHeaderRowCount();
    let totalHeaderHeight: number;
    let numberOfFloating: number = 0;
    let groupHeight: number;
    let headerHeight: number;
    let numberOfNonGroups: number;
    let numberOfGroups: number;

    if (gridOptionsWrapper) {
      if (!columnCtrl.isPivotMode()) {
        if (gridOptionsWrapper.isFloatingFilter()) {
          headerRowCount++;
        }
        numberOfFloating = (gridOptionsWrapper.isFloatingFilter()) ? 1 : 0;
        groupHeight = gridOptionsWrapper.getGroupHeaderHeight();
        headerHeight = gridOptionsWrapper.getHeaderHeight();
      } else {
        numberOfFloating = 0;
        groupHeight = gridOptionsWrapper.getPivotGroupHeaderHeight();
        headerHeight = gridOptionsWrapper.getPivotHeaderHeight();
      }
      numberOfNonGroups = 1 + numberOfFloating;
      numberOfGroups = headerRowCount - numberOfNonGroups;
      totalHeaderHeight = numberOfFloating * gridOptionsWrapper.getFloatingFiltersHeight();
      totalHeaderHeight += numberOfGroups * groupHeight;
      totalHeaderHeight += headerHeight;
      // ag-grid adds 1 extra pixel to the header height to account for the border
      totalHeaderHeight += 1;
    } else {
      totalHeaderHeight = this.gridOptions.headerHeight || 0;
    }
    return totalHeaderHeight;
  }

  /**
   * Subscribes to change events that possibly cause column size changes
   */
  private setupColumnSizeSubscriptions(): void {
    /**
     * Resize the columns when the grid size is changed but execute it only once per throttle function (500ms).
     * This also handles window resize
     */
    this.subs.newSub = this.gridSizeChanged.asObservable().subscribe(() => this._throttledExecSizeColumnsToFitFn());

    /**
     * Resize the columns when the displayed columns change but execute it only once per throttle function (500ms).
     */
    this.subs.newSub = this.displayedColumnsChanged.asObservable().subscribe(() => {
      if (this.defaultExportParams && this.api && this.api.rowEdit) {
        this.defaultExportParams.columnKeys = this.api.rowEdit.getUserColumns().map((column: Column) => column.getColId());
      }
      this._throttledExecSizeColumnsToFitFn();
    });

    /**
     * Resize the columns when displayed rows have changed.
     * Happens following sort, filter or tree expand / collapse events.
     */
    this.subs.newSub = this.modelUpdated.asObservable().subscribe(() => this._throttledExecSizeColumnsToFitFn());

    /**
     * subscribe to row click
     */
    this.subs.newSub = this.rowClicked.asObservable().subscribe(() => this._throttledExecSizeColumnsToFitFn());
  }

  private execSizeColumnsToFit = (): void => {
    let gridApi: UiGridApi = this.api || (this.gridOptions && this.gridOptions.api);
    if (gridApi && gridApi.sizeColumnsToFit && this.isSuppressHorizontalScroll()) {
      gridApi.sizeColumnsToFit();
    }
  }

  private onSelectionChanged = (): void => {
    this.selectedRows.emit(this.api.getSelectedNodes());
  }

    /**
   * Subscribes to change events and keeps data in sync
   */
  protected subscribeToTableChanges(): void {
    this.subs.newSub =
      this._dataChangeEvent.pipe(
        tap(() => this.emitNewData(this.dataChangeRaw)),
        debounceTime(150),)
        .subscribe(() => this.emitNewData(this.dataChange));
  }

  /**
   * emitNewData makes two way binding possible on the grid using [(data)]='myDataVar'
   *
   * When change events are fired they are funneled through our _dataChangeEvent subject and
   * emitted as a dataChange event.  This allows two way binding on the data for the table.
   *
   * There is some overlap in some of these events, for example onRowDataChanged, onRowValueChanged
   * and onCellValue changed.  Depending on what type of change occurs, they may all be fired.
   * For this reason all of these change events are funneled through our single _dataChangeEvent subject
   * and debounced so that subscribing to _dataChangeEvent only produces one change event even when
   * multiple change events are fired off.
   */
  private emitNewData(emitter: EventEmitter<any>): void {
    this.rowData ? this.rowData.length = 0 : this.rowData = [];
    this.api.forEachLeafNode((rowNode: RowNode) => this.rowData.push(rowNode.data));
    emitter.emit(this.rowData);
  }

  /**
   * Fires if the reference to the row data has changed
   */
  private onRowDataChanged = (): void => {
    this._dataChangeEvent.next();
  }

  private onItemsAdded = (items: any): void => {
    items.rowNodes.forEach((n) => {
      n.data.dirtyStatus = DirtyStatus.NEW;
      n.data.validatedStatus = false;
    });
    this._dataChangeEvent.next();
    this.emitNewData(this.rowAdded);
  }

  private onItemsRemoved = (items: any): void => {
    items.rowNodes.forEach((n) => n.data.dirtyStatus = DirtyStatus.DELETE);
    this._dataChangeEvent.next();
    this.emitNewData(this.rowRemoved);
  }

  private onRowValueChanged = (node: RowNode): void => {
    if (node.data.dirtyStatus !== DirtyStatus.NEW) {
      node.data.dirtyStatus = DirtyStatus.UPDATE;
    }
    node.data.validatedStatus = false;
    this._dataChangeEvent.next();
    this.emitNewData(this.rowUpdated);
  }

  private onCellValueChanged = (evt: CellValueChangedEvent): void => {
    let gridRow: any = evt.node;
    if (evt.oldValue !== evt.newValue || gridRow.isDirty) {
      if (this.enableRowModifiedFields) {
        this.setModifiedField(evt.node, evt.colDef, evt.newValue, evt.oldValue);
      }
      if (this.enableRowAutoSave) {
        // fires on cell editing stopped regardless of whether value changed.
        this.rowAutoSaveFactory.endCellEdit(evt.api, evt.node, evt.colDef, evt.newValue, evt.oldValue);
      }
      //  this.cellValueChanged.next(evt);
      if (evt.node.data.dirtyStatus !== DirtyStatus.NEW) {
        evt.node.data.dirtyStatus = DirtyStatus.UPDATE;
      }
      evt.node.data.validatedStatus = false;
      this._dataChangeEvent.next();
      this.emitNewData(this.rowUpdated);
      this.rowCellUpdated.emit(evt);
    }
  }

  private onCellEditingStarted = ($event: any): void => {
    this.rowAutoSaveFactory.beginCellEdit($event.api, $event.node, $event.colDef);
  }

  public onDragStarted(event: DragStartedEvent): void {
    if (event && event.api) {
      // If has editing cells then do not allow cell range selection
      if (!_.isEmpty(event.api.getEditingCells()) && (event.api as any).rangeController) {
        (event.api as any).rangeController.dragging = false;
      }
    }
  }

  private onBodyScroll(table: TableComponent): any {
    if (this.stopEditingOnScroll) {
      setTimeout((): void => {
        this.api.stopEditing();
      });
    }
  }

  private setModifiedField = (gridRow: any, colDef: any, newValue: any, oldValue: any): void => {
    if (_.isUndefined(gridRow.data.modifiedFields)) {
      gridRow.data.modifiedFields = [];
    }
    if (newValue !== oldValue) {
      if (gridRow.data.modifiedFields.indexOf(colDef.field) === -1) {
        gridRow.data.modifiedFields.push(colDef.field);
      }
    }
  }

  private suppressMenuItem(itemName: string): void {
    let shouldRefresh: boolean = false;

    if (itemName && _.isArray(this.columnDefs)) {
      (this.columnDefs as ColDef[]).forEach((colDef: ColDef) => {
        if (!colDef.suppressMenu && _.isArray(colDef.menuTabs)) {
          let index: number = _.findIndex(colDef.menuTabs, itemName);
          if (index > -1) {
            colDef.menuTabs.splice(index, 1);
            shouldRefresh = true;
          }
        }
      });
      if (shouldRefresh) {
        this._throttledRefreshColDefs();
      }
    }
  }

  private onFeatureChanged(featureProp: string, newValue: any): void {
    if (this.configurer) {
      let refreshColDefs: boolean = false;

      switch (featureProp) {
        case 'navigationMenu': {
          this.removeColumn(this.getColDefByColId('navigationMenu'));
          refreshColDefs = this.configurer.setNavigationMenu(this) ? true : refreshColDefs;
          break;
        }
        case 'showSingleSelect': {
          this.removeColumn(this.getColDefByColId('singleSelect'));
          refreshColDefs = this.configurer.setSingleSelect(this) ? true : refreshColDefs;
          break;
        }
        case 'showMultiSelect': {
          this.removeColumn(this.getColDefByColId('multiSelect'));
          // Revert changed gridOption entries to their default states
          if (this.gridOptions && this.gridOptions.rowSelection === 'multiple') {
            this.gridOptions.rowSelection = 'single';
          }
          refreshColDefs = this.configurer.setMultiSelect(this) ? true : refreshColDefs;
          break;
        }
        case 'validationVisual': {
          this.removeColumn(this.getColDefByColId('validationVisual'));
          refreshColDefs = this.configurer.setValidationVisual(this) ? true : refreshColDefs;
          break;
        }
        case 'actionMenu': {
          this.removeColumn(this.getColDefByColId('actionMenu'));
          if (!_.isNil(newValue)
            && _.isArray(newValue.items)
            && newValue.items.length > 0) {
            refreshColDefs = this.configurer.setActionMenu(this) ? true : refreshColDefs;
          }
          break;
        }
        case 'showDelete': {
          this.removeColumn(this.getColDefByColId('showDelete'));
          refreshColDefs = this.configurer.setShowDelete(this) ? true : refreshColDefs;
          break;
        }
        case 'showExpand': {
          this.removeColumn(this.getColDefByColId('showExpand'));
          // Revert changed gridOption entries to their default states
          if (this.gridOptions) {
            this.gridOptions.detailCellRendererFramework = null;
            this.gridOptions.isFullWidthCell = null;
            delete this.gridOptions.detailCellRendererFramework;
            delete this.gridOptions.isFullWidthCell;
          }
          refreshColDefs = this.configurer.setRowChildComponent(this) ? true : refreshColDefs;
          break;
        }
        case 'rowMessage': {
          if (!this.validationVisual) {
            this.removeColumn(this.getColDefByColId('rowMessageIcon'));
            refreshColDefs = this.configurer.setMessageIcon(this) ? true : refreshColDefs;
          }
          break;
        }
      }
      if (refreshColDefs) {
        this.refreshColDefs();
      }
    }
  }

  private getColDefByColId(colId: string): any {
    return (this.columnDefs && !_.isNil(colId) && colId !== '')
      ? _.find(this.columnDefs, { field: colId })
      : null;
  }

  private getContextMenuItemsFn(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
    let contextMenuItems: (string | MenuItemDef)[] = [
      {
        name: 'Copy',
        subMenu: [
          {
            name: 'Selected Cell(s)',
            shortcut: 'Ctrl+C',
            action: () => {
              if (params
                && params.api
                && (params.api as any).clipboardService) {
                // (params.api as any).clipboardService.copyToClipboard(false);
              }
            }
          },
          {
            name: 'Selected Cell(s) with Headers',
            action: () => {
              if (params
                && params.api
                && (params.api as any).clipboardService) {
                (params.api as any).clipboardService.copyToClipboard(true);
              }
            }
          },
          'separator',
          {
            name: 'Row',
            action: () => {
              if (params
                && params.api
                && params.node) {
                params.api.clearRangeSelection();
                params.api.addCellRange({
                  columns: (params.api as UiGridApi).rowEdit.getUserColumns(),
                  rowStartIndex: params.node.rowIndex,
                  rowEndIndex: params.node.rowIndex
                });
                params.api.copySelectedRangeToClipboard(false);
              }
            }
          },
          {
            name: 'Row with Headers',
            action: () => {
              if (params
                && params.api
                && params.node) {
                params.api.clearRangeSelection();
                params.api.addCellRange({
                  columns: (params.api as UiGridApi).rowEdit.getUserColumns(),
                  rowStartIndex: params.node.rowIndex,
                  rowEndIndex: params.node.rowIndex
                });
                params.api.copySelectedRangeToClipboard(true);
              }
            }
          },
          'separator',
          {
            name: 'Table',
            action: () => {
              if (params
                && params.api
                && (params.api as UiGridApi).rowEdit) {
                params.api.clearRangeSelection();
                params.api.addCellRange({
                  columns: (params.api as UiGridApi).rowEdit.getUserColumns(),
                  rowStartIndex: 0,
                  rowEndIndex: Math.max(0, (params.api as UiGridApi).rowEdit.getAllRowNodes().length - 1)
                });
                params.api.copySelectedRangeToClipboard(false);
              }
            }
          },
          {
            name: 'Table with Headers',
            action: () => {
              if (params
                && params.api
                && (params.api as UiGridApi).rowEdit) {
                params.api.clearRangeSelection();
                params.api.addCellRange({
                  columns: (params.api as UiGridApi).rowEdit.getUserColumns(),
                  rowStartIndex: 0,
                  rowEndIndex: Math.max(0, (params.api as UiGridApi).rowEdit.getAllRowNodes().length - 1)
                });
                params.api.copySelectedRangeToClipboard(true);
              }
            }
          }
        ],
        icon: Utils.createIconNoSpan('clipboardCopy', (params.api as any).gridOptionsWrapper, null)
      },
      {
        name: 'Export',
        subMenu: [
          'excelExport',
          'csvExport'
        ],
        icon: Utils.createIconNoSpan('save', (params.api as any).gridOptionsWrapper, null)
      },
      'separator',
      {
        name: 'Print Table',
        action: () => {
          if (params && params.api) {
            this.printTable(params.api);
          }
        }
      },
      {
        name: 'Clear All Filters',
        action: () => {
          if (params && params.api) {
            params.api.setFilterModel({});
          }
        }
      }
    ];
    if (!this.isSuppressHorizontalScroll()) {
      let skipHeaderOnAutoSize: boolean = this.gridOptions && this.gridOptions.skipHeaderOnAutoSize;
      let columnController: ColumnController = (params && params.api)
        ? (params.api as any).columnController as ColumnController
        : null;
      contextMenuItems.push(
        'separator',
        {
          name: 'Column Sizing',
          subMenu: [
            {
              name: `Autosize This (${params.columnApi.getDisplayNameForColumn(params.column, null)}) Column`,
              action: () => {
                if (columnController) {
                  columnController.autoSizeColumn(params.column, skipHeaderOnAutoSize, 'contextMenu');
                }
              }
            },
            {
              name: 'Autosize All Columns',
              action: () => {
                if (columnController) {
                  columnController.autoSizeAllColumns(skipHeaderOnAutoSize, 'contextMenu');
                }
              }
            },
            {
              name: 'Size Columns To Fit',
              action: () => {
                if (params && params.api) {
                  params.api.sizeColumnsToFit();
                }
              }
            }
          ],
          icon: Utils.createIconNoSpan('columns', (params.api as any).gridOptionsWrapper, null)
        }
      );
    }
    contextMenuItems.push(
      'separator',
      {
        name: 'Preferences',
        subMenu: [
          {
            name: 'Save Preferences',
            action: () => {
              if (this.userPrefs
                && params
                && params.columnApi) {
                this.userPrefs.addTablePreference(this, [ params.columnApi.getColumnState(), params.api.getSortModel() ]);
              }
            }
          },
          {
            name: 'Delete Preferences',
            action: () => {
              if (this.userPrefs
                && params
                && params.columnApi) {
                this.userPrefs.deleteTablePreference(this, params.columnApi, params.api);
              }
            }
          },
          {
            name: 'Reload Preferences',
            action: () => {
              if (params) {
                if (params.columnApi && this.columnState) {
                  params.columnApi.setColumnState(this.columnState);
                }
                if (params.api && this.sortModel) {
                  params.api.setSortModel(this.sortModel);
                }
              }
            }
          }
        ],
        icon: Utils.createIconNoSpan('menuPin', (params.api as any).gridOptionsWrapper, null)
      },
      ...this.customContextMenuItems
    );
    return contextMenuItems;
  }

  private processCellCallbackFn(params: ProcessCellForExportParams): string {
    return (params
    && params.api
    && (params.api as UiGridApi).rowEdit
    && params.node
    && params.column)
      ? (params.api as UiGridApi).rowEdit.getFormattedValue(params.column.getColId(), params.node)
      : '';
  }

  private getFormattedDateForExcel(dateString: string): string {
    return dateString ? moment(dateString).local().format('YYYY-MM-DDTHH:mm:ss.SSS') : '';
  }

  /**
   * @description This fixes IE selecting extra text when selecting multiple
   * rows using shift + click
   * @see https://github.com/ag-grid/ag-grid/issues/1613
   */
  private fixIESelectionBug(): void {
    if (this.isBrowserIE()
      && this.gridOptions
      && this.gridOptions.rowSelection === 'multiple') {
      this.subs.newSub = this.rowSelected.asObservable().subscribe(() => {
        if (window
          && _.isFunction(window.getSelection)
          && _.isFunction(window.getSelection().removeAllRanges)) {
          window.getSelection().removeAllRanges();
        }
      });
    }
  }

  private isBrowserIE(): boolean {
    return !!(document as any).documentMode; // At least IE6
  }

  public getCurrentRouteUrl(input: any): string {
    if (!_.isNil(input.value.routeConfig.path) && input.value.routeConfig.path !== '') {
      this.routeUrl = this.routeUrl + '/' + input.value.routeConfig.path;
    }
    if (input.children[ 0 ]) {
      this.getCurrentRouteUrl(input.children[ 0 ]);
    }
    return this.routeUrl;
  }

  private printTable(gridApi: UiGridApi): void {
    let printHtmlText: string = this.id
      ? ('<H3 ALIGN="LEFT">' + _.startCase(this.id) + '</H3>')
      : '';
    let printWin: Window;

    printHtmlText += this.printScreenService.convertGridToHTML(gridApi);
    printWin = window.open('', 'PrintWindow', 'toolbars=no,scrollbars=no,status=no,resizable=yes');
    printWin.document.write(this.printScreenService.getFullHTML(printHtmlText));
    printWin.document.close();
    printWin.focus();
    fromEvent(printWin, 'afterprint')
    .pipe(take(1))
    .subscribe(() => printWin.close());
    printWin.print();
  }
}
