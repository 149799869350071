
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiCache } from 'cad/common/services/api/api-cache';
import { ApiHelper } from 'cad/common/services/api/api-helper';
import { AgreementInputData } from 'src/cad/contracts/shared/components/dynamic-input-tables/types/dynamic-input-tables-types';
import ValidationResult = cad.ValidationResult;

@Injectable()
export class AgreementContractRightsApi {

  private readonly endpoint: string = 'agreement/contract';

  constructor(
    private apiHelper: ApiHelper,
    private apiCache: ApiCache,
  ) {}

  public getById = (id: string | number): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/rights/by-id/${ id }`);
  }

  public getRightsByType = (contractId: string | number, type: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }${ type }`);
  }

  public getInputs = (itemData: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ itemData.contractId }/inputs`);
  }

  public getProductData = (contractId: string | number): Observable<any> => {
    return this.getProductDataWithScopes(contractId);
  }
  
  public getProductDataWithScopes(contractId: string | number): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/rights-with-scopes`);
  }
  
  public getProductDataWithScopesForDate(contractId: string | number, effDate: string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/rights-with-scopes-for-date`, { body: JSON.stringify(effDate) });
  }

  public rightsPointLookup = (params: any): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/rights/point/lookup`, { body: params });
  }

  public rightsRecurrenceLookup = (): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/rights/recurrencePatterns/lookup`).pipe(map((resp) => {
      return Object.getOwnPropertyNames(resp).map((el) => ({ patternCode: el, patternName: resp[el] }));
    }));
  }

  public save = (data: any): Observable<any> => {
    let rows = Object.getOwnPropertyNames(data).map((el) => data[el]);
    let contractId = rows[0].agreementId;
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/inputs`, { body: rows });
  }

  public runRightsConfigurator = (contractId: number, quiet?: boolean): Observable<any> => {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/configurator`, { quiet });
  }
  
  public runRollUp(contractId: number): Observable<ValidationResult> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/rollup`);
  }

  public getDivisionAssociatedPointList(code: number | string): Observable<any> {
    return this.apiHelper.request(this.endpoint + '/rights/division-assoc-points/' + code);
  }
  
  public getSeasonalContractInfInputs(contractId: string | number, params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-contract-inputs`, { body: params });
  }
  
  public getSeasonalPointInputs(contractId: string | number, params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-point-inputs`, { body: params });
  }
  
  public getSeasonalContractInfInputsForDate(contractId: string | number, effDate: string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-contract-inputs-for-date`, { body: JSON.stringify(effDate) });
  }
  
  public getSeasonalPointInputsForDate(contractId: string | number, effDate: string): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-point-inputs-for-date`, { body: JSON.stringify(effDate) });
  }
  
  public getSeasonalContractNNTSInputs(contractId: string | number, params: any): Observable<any> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-contractNNTS-inputs`, { body: params });
  }
  
  public getSeasonalContractNNTSInfInputsForDate(contractId: string | number, effDate: string): Observable<AgreementInputData> {
    return this.apiHelper.request(`${ this.endpoint }/rights/${ contractId }/seasonal-contractNNTS-inputs-for-date`, { body: JSON.stringify(effDate) });
  }
}
